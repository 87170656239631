// FONT STACKS
// ----------------------------------------------------------------------------

// Fallback fonts
$font__fallback--sans: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font__fallback--serif: ("Georgia", "Garamond", "Baskerville", serif);
$font__fallback--monospace: ("Courier New", "Lucida Sans Typewriter", "Lucida Console", monospace);

// Helper variables for all font types
$font__types: (
  "thin":              ("weight": 100, "style": normal),
  "thin-italic":       ("weight": 100, "style": italic),
  "extralight":        ("weight": 200, "style": normal),
  "extralight-italic": ("weight": 200, "style": italic),
  "light":             ("weight": 300, "style": normal),
  "light-italic":      ("weight": 300, "style": italic),
  "regular":           ("weight": 400, "style": normal),
  "regular-italic":    ("weight": 400, "style": italic),
  "medium":            ("weight": 500, "style": normal),
  "medium-italic":     ("weight": 500, "style": italic),
  "semibold":          ("weight": 600, "style": normal),
  "semibold-italic":   ("weight": 600, "style": italic),
  "bold":              ("weight": 700, "style": normal),
  "bold-italic":       ("weight": 700, "style": italic),
  "extrabold":         ("weight": 800, "style": normal),
  "extrabold-italic":  ("weight": 800, "style": italic),
  "black":             ("weight": 900, "style": normal),
  "black-italic":      ("weight": 900, "style": italic)
);

// Primary font stack
$font--primary: (
  "family": "Eintracht-Sans", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular",
    "regular-italic",
    "medium",
    "medium-italic",
    "bold",
    "bold-italic"
  )
);

// Primary font stack
$font--headline: (
  "family": "Eintracht-Display-Headline", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular"
  )
);

// Primary font stack
$font--headline-condensed: (
  "family": "Eintracht-Display-Headline-Condensed", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular"
  )
);

// Primary font stack
$font--subline: (
  "family": "Eintracht-Display-Subline", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "light",
    "light-italic",
    "regular",
    "regular-italic"
  )
);

// Primary font stack
$font--subline-condensed: (
  "family": "Eintracht-Display-Subline-Condensed", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular"
  )
);

// Primary font stack
$font--serif: (
  "family": "Eintracht-Serif", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--serif),
  "types": (
    "regular",
    "regular-italic",
    "medium",
    "medium-italic",
    "bold",
    "bold-italic"
  )
);

$font--mono: (
  "family": "Eintracht-Mono", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--monospace),
  "types": (
    "regular"
  )
);

// Deutsche Bank Park font stack
$font--dbp: (
  "family": "NimbusSansRound", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular",
    "regular-italic",
    "bold",
    "bold-italic"
  )
);

// 100 Jahre Waldstadion font stacks
$font--kabel: (
  "family": "Kabel", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular"
  )
);

$font--turnip: (
  "family": "Turnip", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "light-italic",
    "black"
  )
);

// Indeed font
$font--indeed: (
  "family": "NotoSans", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular",
    "bold"
  )
);

$font--touchdown: (
  "family": "Capture-It", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "bold"
  )
);

$font--jersey: (
  "family": "PosterGothic", // IMPORTANT: No spaces if font-face
  "fallback": ($font__fallback--sans),
  "types": (
    "regular"
  )
);

@include font-face($font--primary);
@include font-face($font--headline);
@include font-face($font--headline-condensed);
@include font-face($font--subline);
@include font-face($font--subline-condensed);
@include font-face($font--serif);
@include font-face($font--mono);

@include font-face($font--dbp);
@include font-face($font--kabel);
@include font-face($font--turnip);
@include font-face($font--indeed);
@include font-face($font--touchdown);
@include font-face($font--jersey);

// Use fonts anywhere
@function font($font) {
  @return map-get($font, "family"), map-get($font, "fallback");
}


// Flock font
@font-face {
  font-family: "FlySoon";
  src: url("/assets/fonts/Eintracht-Font-1920/eintracht-font-1920.woff2")
    format("woff2"), url("/assets/fonts/Eintracht-Font-1920/eintracht-font-1920.woff")
    format("woff"), url("/assets/fonts/Eintracht-Font-1920/eintracht-font-1920.otf")
    format("opentype");
  font-style: normal;
  font-weight: 400;
}
